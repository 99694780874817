@font-face {
  font-family: "Montserrat";
  src: local("Montserrat-SemiBold"), url("assets/fonts/Montserrat-SemiBold.woff2") format("woff2"),
    url("assets/fonts/Montserrat-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat-Regular"), url("assets/fonts/Montserrat-Regular.woff2") format("woff2"),
    url("assets/fonts/Montserrat-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat-ExtraBold"), url("assets/fonts/Montserrat-ExtraBold.woff2") format("woff2"),
    url("assets/fonts/Montserrat-ExtraBold.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

body {
  color: #333344 !important;
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

ul {
  list-style: none;
  padding-left: 14px;
}

li:before {
  content: "•";
  float: left;
  margin-left: -9px;
}

ol li:before,ul.ant-list-items li:before {
  content: "";
  float: none;
  margin-left: 0;
}

li.ant-dropdown-menu-item:before,
li.ant-menu-item:before {
  content: "";
  float: none;
  margin-left: 0;
}

ul.ant-timeline {
  li:before {
    content: "";
    float: none;
    margin-left: 0;
  }
}

li {
  margin-bottom: 5px;
}

#root {
  height: 100%;
  width: 100%;
}

.ant-btn.ant-btn-link:focus,
.ant-btn.ant-btn-link:focus * {
  text-decoration: underline;
}

.ant-form-item-has-error .ant-input,
.ant-form-item-has-error .ant-input-number-input,
.ant-form-item-has-error .ant-select-selection-placeholder {
  border: 1px solid #eb5757 !important;
}

.ant-form-item-explain.ant-form-item-explain {
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0.1px;
  line-height: 12px;
}

.ant-select.ant-select {
  .ant-select-selector.ant-select-selector {
    align-items: center;
    background-color: #ffffff;
    border: none;
    display: flex;
    height: 32px;
    padding: 0;
  }

  .ant-select-selection-search.ant-select-selection-search {
    left: 0;
    width: 100%;
  }

  .ant-select-selection-search-input.ant-select-selection-search-input.ant-select-selection-search-input {
    border: 1px solid #205a9d;
    border-radius: 2px;
    margin: 0 !important;
    opacity: 1 !important;
    padding: 0 10px 0 8px;

    &[value=""] {
      border: none;
    }
  }

  .ant-select-selection-placeholder.ant-select-selection-placeholder,
  .ant-select-selection-item.ant-select-selection-item {
    border: 1px solid #bdbdbd;
    border-radius: 2px;
    padding: 0 10px 0 8px;
  }

  .ant-select-selection-placeholder.ant-select-selection-placeholder {
    color: #bdbdbd;
    opacity: 1;
  }

  .ant-select-selection-item.ant-select-selection-item {
    border: 1px solid #205a9d;
  }

  .ant-select-selection-search-input.ant-select-selection-search-input.ant-select-selection-search-input {
    height: 100%;
  }

  &.ant-select-focused .ant-select-selector.ant-select-selector {
    border-color: #205a9d !important;
    box-shadow: 0 0 0 2px rgba(32, 89, 173, 0.2);
  }

  .ant-select-arrow {
    color: #333344;
  }

  &::placeholder {
    color: #333344;
    opacity: 0.5;
  }
}

.ant-form-vertical {
  .ant-form-item.ant-form-item-with-help {
    margin-bottom: 0;
  }

  .ant-form-item {
    margin-bottom: 12px;
  }

  .ant-form-item-label > label {
    height: auto;
  }
}

.ant-form-item-label > label.ant-form-item-required::before {
  content: "" !important;
  display: none !important;
}

.ant-form-item-label > label.ant-form-item-required::after {
  display: inline-block !important;
  margin-right: 4px;
  font-size: 14px;
  line-height: 1;
  content: '*';
}

.ant-result-subtitle.ant-result-subtitle {
  color: inherit;
}

.PhoneInputCountrySelect {
  &[disabled] {
    cursor: not-allowed;
  }
}

.PhoneInputInput {
  border: 1px solid #205a9d;

  &[disabled] {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    cursor: not-allowed;
    opacity: 1 !important;
  }

  &[value=""] {
    border: 1px solid #bdbdbd;
  }

  &:focus {
    border-color: #205a9d;
    box-shadow: 0 0 0 2px rgba(32, 89, 173, 0.2) !important;
  }

  &::placeholder {
    color: #bdbdbd;
    letter-spacing: 0.1px;
  }
}

.PhoneInputCountryIcon {
  line-height: 1;
}
